var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details"},[_c('section',{staticClass:"bet_group page_container"},[_c('div',{staticClass:"bet-item"},[_c('div',{staticClass:"box_one"},[_c('div',{staticClass:"statuses"},[_c('span',{class:_vm.getStatusName(_vm.bet) === `${_vm.$t('cancelled')}`
                ? 'cancelled'
                : _vm.getStatusName(_vm.bet) === `${_vm.$t('won')}`
                ? 'won'
                : _vm.getStatusName(_vm.bet) === `${_vm.$t('lost')}`
                ? 'lost'
                : _vm.getStatusName(_vm.bet) === `${_vm.$t('pending')}`
                ? 'pending'
                : _vm.getStatusName(_vm.bet) === `${_vm.$t('voided')}`
                ? 'voided'
                : 'closed'},[_vm._v(" "+_vm._s(_vm.getStatusName(_vm.bet))+" ")])]),_c('h3',[_vm._v(_vm._s(_vm.$t("betId"))+": "+_vm._s(_vm.bet?.code))])]),_c('div',{staticClass:"box_two"},[_c('p',[_vm._v(_vm._s(_vm.formatDateTime(_vm.bet?.created)))])])])]),_c('section',{staticClass:"page_container"},[_c('div',{staticClass:"overview_flex"},[_c('span',[_vm._v(_vm._s(_vm.$t("totalOdds"))+":")]),_c('span',[_vm._v(_vm._s(_vm.bet?.total_odd)+_vm._s(" ")+_vm._s(_vm.fiatCurrency))])]),_c('div',{staticClass:"overview_flex"},[_c('span',[_vm._v(_vm._s(_vm.$t("stake"))+":")]),_c('span',[_vm._v(_vm._s(_vm.bet?.stake)+" "+_vm._s(_vm.fiatCurrency))])]),_c('div',{staticClass:"overview_flex"},[_c('span',[_vm._v(_vm._s(_vm.$t("possibleWin"))+":")]),_c('span',[_vm._v(_vm._s(_vm.comaFormated(_vm.bet?.possible_win, _vm.lang))+_vm._s(" ")+_vm._s(_vm.fiatCurrency))])]),(_vm.getStatusName(_vm.bet) === `${_vm.$t('won')}`)?_c('div',[_c('div',{staticClass:"overview_flex"},[_c('span',[_vm._v(_vm._s(_vm.$t("eventWon"))+":")]),_c('span',[_vm._v(" "+_vm._s(_vm.bet?.picked.length)+"/"+_vm._s(_vm.bet?.picked.length)+" ")])])]):_vm._e()]),_c('div',{staticClass:"bet_head"},[_c('div',{staticClass:"page_container"},[_c('span',[_vm._v(_vm._s(_vm.$t("bets")))])])]),_vm._l((_vm.bet?.picked),function(picked,index){return _c('div',{key:_vm.bet.id + index,staticClass:"bet_item"},[_c('div',{staticClass:"bet_details page_container"},[_c('div',{staticClass:"box_one"},[_c('span',[_vm._v(_vm._s(picked?.match_date && _vm.formatDateTime(picked?.match_date)))]),_c('p',[_vm._v(" "+_vm._s(picked.match_name)+" ")]),_c('span',[_vm._v(" "+_vm._s(picked.tournament))]),_c('span',[_vm._v(_vm._s(_vm.$t("yourPick"))+": "+_vm._s(picked?.market_name)+" • "+_vm._s(picked.outcome_name))])]),_c('div',{staticClass:"box_two"},[_c('p',[_vm._v(_vm._s(picked?.odd))]),_c('span',{class:_vm.getStatusNameForPick(picked) === `${_vm.$t('won')}`
              ? 'bet_status won'
              : _vm.getStatusNameForPick(picked) === `${_vm.$t('pending')}`
              ? 'bet_status pending'
              : _vm.getStatusNameForPick(picked) === `${_vm.$t('lost')}`
              ? 'bet_status lost'
              : _vm.getStatusNameForPick(picked) === `${_vm.$t('void')}`
              ? 'bet_status void'
              : 'bet_status'})])])])}),_c('div',{staticClass:"page_container"},[_c('div',{staticClass:"rules"},[_c('span',[_vm._v(_vm._s(_vm.$t("betPlaced"))+" "+_vm._s(_vm.formatDateTime(_vm.bet.created)))]),_c('p',[_vm._v(_vm._s(_vm.$t("maximumWinnings"))+" "+_vm._s(_vm.fiatCurrency))]),_c('div',[_c('p',[_c('span',{staticClass:"bet_status pending"}),_c('span',[_vm._v(_vm._s(_vm.$t("pending")))])]),_c('p',[_c('span',{staticClass:"bet_status won"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("won")))])]),_c('p',[_c('span',{staticClass:"bet_status void"}),_c('span',[_vm._v(_vm._s(_vm.$t("voided")))])]),_c('p',[_c('span',{staticClass:"bet_status cancelled"}),_c('span',[_vm._v(_vm._s(_vm.$t("cancelled")))])]),_c('p',[_c('span',{staticClass:"bet_status lost"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("lost")))])])])]),_c('div',{staticClass:"action_groups"},[(_vm.getStatusName(_vm.bet) === `${_vm.$t('cashout')}`)?_c('div',[_c('ChopbetButton',{attrs:{"variant":"Primary"}},[_vm._v(_vm._s(_vm.$t("requestCashout"))+" ")])],1):_vm._e()])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="details">
    <section class="bet_group page_container">
      <div class="bet-item">
        <div class="box_one">
          <div class="statuses">
            <span
              :class="
                getStatusName(bet) === `${$t('cancelled')}`
                  ? 'cancelled'
                  : getStatusName(bet) === `${$t('won')}`
                  ? 'won'
                  : getStatusName(bet) === `${$t('lost')}`
                  ? 'lost'
                  : getStatusName(bet) === `${$t('pending')}`
                  ? 'pending'
                  : getStatusName(bet) === `${$t('voided')}`
                  ? 'voided'
                  : 'closed'
              "
            >
              {{ getStatusName(bet) }}
            </span>
          </div>
          <h3>{{ $t("betId") }}: {{ bet?.code }}</h3>
        </div>
        <div class="box_two">
          <p>{{ formatDateTime(bet?.created) }}</p>
        </div>
      </div>
    </section>
    <section class="page_container">
      <div class="overview_flex">
        <span>{{ $t("totalOdds") }}:</span>
        <span>{{ bet?.total_odd }}{{ " " }}{{ fiatCurrency }}</span>
      </div>
      <div class="overview_flex">
        <span>{{ $t("stake") }}:</span>
        <span>{{ bet?.stake }} {{ fiatCurrency }}</span>
      </div>

      <div class="overview_flex">
        <span>{{ $t("possibleWin") }}:</span>
        <span
          >{{ comaFormated(bet?.possible_win, lang) }}{{ " "
          }}{{ fiatCurrency }}</span
        >
      </div>
      <div v-if="getStatusName(bet) === `${$t('won')}`">
        <div class="overview_flex">
          <span>{{ $t("eventWon") }}:</span>
          <span>
            <!-- {{ comaFormated(bet?.possible_win, lang) }}{{ " "
            }}{{ fiatCurrency }} -->
            {{ bet?.picked.length }}/{{ bet?.picked.length }}
          </span>
        </div>
        <!-- <div class="overview_flex">
          <span>Excise Tax:</span>
          <span
            >{{ comaFormated(bet?.tax_on_stake, lang) }}{{ " "
            }}{{ fiatCurrency }}</span
          >
        </div> -->
        <!-- <div class="overview_flex">
          <span>Withholding Tax:</span>
          <span
            >{{ comaFormated(bet?.tax_on_winning, lang) }}{{ " "
            }}{{ fiatCurrency }}</span
          >
        </div> -->
      </div>
    </section>
    <div class="bet_head">
      <div class="page_container">
        <span>{{ $t("bets") }}</span>
      </div>
    </div>
    <div
      v-for="(picked, index) in bet?.picked"
      :key="bet.id + index"
      class="bet_item"
    >
      <div class="bet_details page_container">
        <div class="box_one">
          <span>{{
            picked?.match_date && formatDateTime(picked?.match_date)
          }}</span>
          <p>
            {{ picked.match_name }}
          </p>
          <span> {{ picked.tournament }}</span>
          <span
            >{{ $t("yourPick") }}: {{ picked?.market_name }} •
            {{ picked.outcome_name }}</span
          >
        </div>
        <div class="box_two">
          <p>{{ picked?.odd }}</p>
          <span
            :class="
              getStatusNameForPick(picked) === `${$t('won')}`
                ? 'bet_status won'
                : getStatusNameForPick(picked) === `${$t('pending')}`
                ? 'bet_status pending'
                : getStatusNameForPick(picked) === `${$t('lost')}`
                ? 'bet_status lost'
                : getStatusNameForPick(picked) === `${$t('void')}`
                ? 'bet_status void'
                : 'bet_status'
            "
          >
          </span>
        </div>
      </div>
    </div>
    <div class="page_container">
      <div class="rules">
        <span>{{ $t("betPlaced") }} {{ formatDateTime(bet.created) }}</span>
        <p>{{ $t("maximumWinnings") }} {{ fiatCurrency }}</p>
        <div>
          <p>
            <span class="bet_status pending"></span>
            <span>{{ $t("pending") }}</span>
          </p>
          <p>
            <span class="bet_status won"></span> <span>{{ $t("won") }}</span>
          </p>
          <p>
            <span class="bet_status void"></span>
            <span>{{ $t("voided") }}</span>
          </p>
          <p>
            <span class="bet_status cancelled"></span>
            <span>{{ $t("cancelled") }}</span>
          </p>
          <p>
            <span class="bet_status lost"></span> <span>{{ $t("lost") }}</span>
          </p>
        </div>
      </div>
      <div class="action_groups">
        <div v-if="getStatusName(bet) === `${$t('cashout')}`">
          <ChopbetButton variant="Primary"
            >{{ $t("requestCashout") }}
          </ChopbetButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import {
  comaFormated,
  formatBetStatusOrOutcome,
  formatDateTime,
} from "../../../utils/formatter";

export default {
  name: "MyBetDetails",

  mounted() {
    console.log(this.bet, "picked");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  props: {
    bet: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    getStatusNameCasino: {
      type: Function,
      required: true,
    },
    getStatusName: {
      type: Function,
      required: true,
    },
  },
  components: {
    ChopbetButton,
  },
  data: function () {
    return {
      lang: this.$i18n.locale,
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      isModalOpen: false,
    };
  },
  methods: {
    comaFormated,
    formatDateTime,
    formatBetStatusOrOutcome,
    getStatusNameForPick: function (picked) {
      // if (picked.status === -1) {
      //   return `${this.$t("cancelled")}`;
      // }
      // if (picked.status === 0) {
      //   return `${this.$t("pending")}`;
      // }
      // if (picked.status === 1) {
      //   return `${this.$t("lost")}`;
      // }
      // if (picked.status === 2) {
      //   return `${this.$t("won")}`;
      // }
      // if (picked.status === 3) {
      //   return `${this.$t("voided")}`;
      // }
      // if (picked.status === 5) {
      //   return `${this.$t("cashout")}`;
      // }
      if (picked.won === 1) {
        return `${this.$t("won")}`;
      }
      if (picked.won === 0) {
        return `${this.$t("lost")}`;
      }
      return "";
    },
  },
};
</script>

<style scoped src="./index.css"></style>

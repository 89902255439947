<template>
  <ChopbetSlideUp :isOpen="isOpen" @closeSlideup="closeSlideUp">
    <div class="learn_more">
      <div v-if="actionType === 'won_status'" class="filter_contents">
        <p>
          <span>{{ $t("betStatus") }}</span>
          <span @click="closeSlideUp"><CancelIcon /></span>
        </p>
        <div
          v-for="item in casinoStatus"
          :key="item.id"
          class="item"
          @click="handleSelectedStatus(item)"
        >
          <span
            ><span>{{ item.name }}</span>
            <span v-if="selectedCasinoStatus.value === item.value">
              <TickCircle />
            </span>
          </span>
        </div>
      </div>
      <div v-if="actionType === 'status'" class="filter_contents">
        <p>
          <span>{{ $t("betStatus") }}</span>
          <span @click="closeSlideUp"><CancelIcon /></span>
        </p>
        <div
          v-for="item in statusFilter"
          :key="item.id"
          class="item"
          @click="handleSelectedStatus(item)"
        >
          <span
            ><span>{{ item.name }}</span>
            <span v-if="selectedStatus.value === item.value">
              <TickCircle />
            </span>
          </span>
        </div>
      </div>
      <div v-if="actionType === 'time'" class="filter_contents">
        <p>
          <span>{{ $t("timePeriod") }}</span>
          <span @click="closeSlideUp"><CancelIcon /></span>
        </p>
        <div v-if="timeCustom" class="custom-time">
          <div>
            <div class="start-date">
              <label for="">{{ $t("from") }}</label>
              <input
                type="date"
                v-model="selectedStartDate"
                @change="handleDateChange(selectedStartDate, 'start')"
                class="date-picker"
                placeholder="Date"
              />
            </div>
            <div class="start-date">
              <label for="">{{ $t("to") }}</label>
              <input
                type="date"
                v-model="selectedEndDate"
                @change="handleDateChange(selectedEndDate, 'end')"
                class="date-picker"
                placeholder="Date"
              />
            </div>
          </div>
          <ChopbetButton @click="handleSubmitDate" variant="primary">{{
            $t("done")
          }}</ChopbetButton>
        </div>
        <div
          v-else
          v-for="item in timeFilter"
          :key="item.id"
          class="item"
          @click="handleSelectedDate(item)"
        >
          <span
            ><span>{{ item.name }}</span>
            <span v-if="selectedTime.value === item.value">
              <TickCircle /> </span
          ></span>
        </div>
      </div>
      <div v-if="actionType === 'bet_type'" class="filter_contents">
        <p>
          <span>{{ $t("betType") }}</span>
          <span @click="closeSlideUp"><CancelIcon /></span>
        </p>
        <div
          v-for="item in casinoBetType"
          :key="item.id"
          class="item"
          @click="handleSelectedStatus(item)"
        >
          <span
            ><span>{{ item.name }}</span>
            <span v-if="selectedBetType.value === item.value">
              <TickCircle />
            </span>
          </span>
        </div>
      </div>
    </div>
  </ChopbetSlideUp>
</template>

<script>
import ChopbetSlideUp from "@/components/ui/ChopbetSlideUp.vue";
import CancelIcon from "@/components/icons/CancelIcon.vue";
import TickCircle from "../../../components/icons/TickCircle.vue";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";

export default {
  name: "MyBetsFilter",
  components: {
    ChopbetSlideUp,
    CancelIcon,
    TickCircle,
    ChopbetButton,
  },
  data: function () {
    return {
      lang: this.$i18n.locale,
    };
  },
  props: {
    isOpen: { required: true, default: false },
    closeSlideUp: { required: true },
    casinoStatus: { type: Array, required: true },
    actionType: { type: String, required: true },
    statusFilter: { type: Array, required: true },
    casinoBetType: { type: Array, required: true },
    handleSelectedStatus: { required: true, default: null },
    timeFilter: { type: Array, required: true },
    selectedCasinoStatus: { required: true },
    selectedStatus: { required: true },
    selectedTime: { required: true },
    selectedBetType: { required: true },
    handleSelectedDate: { required: true },
    selectedStartDate: { type: String, required: true }, // Ensure date format validation if needed
    selectedEndDate: { type: String, required: true }, // Ensure date format validation if needed
    handleDateChange: { type: Function, required: true },
    handleSubmitDate: { type: Function, required: true },
    timeCustom: { type: Boolean, required: true, default: false },
  },
  watch: {
    timeCustom() {
      console.log(this.timeCustom, "isOpenisOpenisOpen", this.selectedTime);
    },
  },
};
</script>

<style scoped src="./index.css"></style>

import bettingserve from "../services/bettingserve";

export const fetchFromBookingCode = async (payloadData) => {
  try {
    console.log(payloadData, "payload");
    var path = `/bet?lang=${this.$i18n.locale}`;
    const res = await bettingserve.post(path, JSON.stringify(payloadData), {
      headers: {
        "api-key": this.getAuth(),
      },
    });
    return res?.data;
  } catch (error) {
    console.error("Error fetching data:", error);

    // if (parseInt(err.response.status) === 401) {
    //   this.setError(message);
    //   vm.logout();
    //   return;
    // }
  } finally {
    console.log("finally");
  }
};

// export const fetchMyBets = async ({
//   apiKey,
//   page = 1,
//   per_page = 10,
//   start = "",
//   end = "",
//   sort = "created|desc",
// }) => {
//   const queryParams = {
//     page,
//     per_page,
//     start,
//     end,
//     sort,
//   };

//   const path = `${process.env.VUE_APP_BASE_BETTING_URL}/bet`;
//   const response = await bettingserve.get(path, {
//     headers: { "api-key": apiKey },
//     params: queryParams,
//   });
//   return response.data;
// };

export const fetchMyBets = async ({
  page = 1,
  per_page = 10,
  apiKey,
  status,
  start = "",
  end = "",
}) => {
  const path = `${process.env.VUE_APP_BASE_BETTING_URL}/bet`;
  var queryParams = {
    page,
    per_page,
  };

  if (status) {
    queryParams = { ...queryParams, status };
  }
  if (start && end) {
    queryParams = { ...queryParams, end, start };
  }
  try {
    const response = await bettingserve.get(path, {
      headers: {
        "api-key": apiKey,
      },
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    let errorMessage = "An error occurred while fetching bets";

    if (error.response) {
      const status = error.response.status;
      errorMessage = error.response.data.message || errorMessage;

      if ([401, 400, 428].includes(status)) {
        errorMessage = "Your session on this device has expired";
        return { error: true, message: errorMessage };
      }
    }
    throw new Error(errorMessage);
  }
};

export const fetchFreeBets = async (apiKey, profile) => {
  if (!profile) {
    throw new Error(`${this.$t("pleaseLoginProceed")}`); 
  }

  const path = `${process.env.VUE_APP_BASE_FREEBET_URL}/bet`;
  const response = await bettingserve.get(path, {
    headers: { "api-key": apiKey },
  });
  return response.data;
};

export const fetchCasinoBets = async ({
  page = 1,
  per_page = 10,
  apiKey,
  won,
  bet_type,
  start = "",
  end = "",
}) => {
  var queryParams = {
    page,
    per_page,
    won,
    start,
    end,
    bet_type,
  };

  if (won) {
    // queryParams = { ...queryParams, won };
  }
  if (bet_type) {
    queryParams = { ...queryParams, bet_type };
  }

  // if (start && end) {
  //   queryParams = { ...queryParams, end, start };
  // }

  try {
    const path = `${process.env.VUE_APP_BASE_CASINOSERVE_URL}/bets`;
    const response = await bettingserve.get(path, {
      headers: { "api-key": apiKey },
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    let errorMessage = "An error occurred while fetching bets";
    if (error.response) {
      const status = error.response.status;
      errorMessage = error.response.data.message || errorMessage;

      if ([401, 400, 428].includes(status)) {
        errorMessage = "Your session on this device has expired";
        return { error: true, message: errorMessage };
      }
    }
    throw new Error(errorMessage);
  }
};

export const fetchJackpotBets = async ({
  page = 1,
  per_page = 10,
  apiKey,
  jackpotId,
}) => {
  const queryParams = {
    page,
    per_page,
    jackpotId,
  };

  const path = `${process.env.VUE_APP_BASE_JACKPOT_URL}/jackpot/${jackpotId}/bet`;
  const response = await bettingserve.get(path, {
    headers: { "api-key": apiKey },
    params: queryParams,
  });

  return response.data;
};

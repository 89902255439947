var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bet_group"},[(_vm.betsData.length < 1 && !_vm.loadingGames)?_c('div',[_c('NoData',{attrs:{"title":_vm.$t('noAvailableBets'),"description":_vm.$t('youDontHaveBet'),"cta":_vm.$t('placeABet'),"noDataTo":"/bet-slip"}})],1):_vm._e(),(!_vm.loadingGames && _vm.betsData && _vm.betsData.length > 0)?_c('div',_vm._l((_vm.betsData),function(bet,idx){return _c('div',{key:bet.id + idx,staticClass:"date-group"},[_c('div',[(_vm.isActive('games'))?_c('div',{staticClass:"bet-item"},[_c('div',{staticClass:"box_one"},[_c('p',[_vm._v(" "+_vm._s(bet.game_name)+" ")]),_c('div',{staticClass:"statuses"},[_c('span',{class:_vm.getStatusNameCasino(bet) === `${_vm.$t('won')}`
                    ? 'won'
                    : _vm.getStatusNameCasino(bet) === `${_vm.$t('lost')}`
                    ? 'lost'
                    : 'pending'},[_vm._v(" "+_vm._s(_vm.getStatusNameCasino(bet))+" ")])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("stake"))+": "),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.comaFormated(bet.stake, _vm.lang))+" "+_vm._s(_vm.fiatCurrency))])])]),_c('div',{staticClass:"box_two"},[_c('p',[_vm._v(_vm._s(_vm.formatDateTime(bet.created)))])])]):_c('div',{staticClass:"bet-item",on:{"click":function($event){return _vm.handleDetailsScreen(bet)}}},[_c('div',{staticClass:"box_one"},[_c('div',{staticClass:"statuses"},[_c('span',{class:_vm.getStatusName(bet) === `${_vm.$t('cancelled')}`
                    ? 'cancelled'
                    : _vm.getStatusName(bet) === `${_vm.$t('won')}`
                    ? 'won'
                    : _vm.getStatusName(bet) === `${_vm.$t('lost')}`
                    ? 'lost'
                    : _vm.getStatusName(bet) === `${_vm.$t('pending')}`
                    ? 'pending'
                    : _vm.getStatusName(bet) === `${_vm.$t('voided')}`
                    ? 'voided'
                    : _vm.getStatusName(bet) === `${_vm.$t('cashout')}`
                    ? 'cashout'
                    : 'closed'},[_vm._v(" "+_vm._s(_vm.getStatusName(bet))+" ")])]),_c('h3',[_vm._v(_vm._s(_vm.$t("betId"))+" "+_vm._s(bet.code))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("possiblePayout"))+" "),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.comaFormated(bet.possible_win, _vm.lang))+" "+_vm._s(_vm.fiatCurrency))])])]),_c('div',{staticClass:"box_two"},[_c('p',[_vm._v(_vm._s(_vm.formatDateTime(bet.created)))])])])])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
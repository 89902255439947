<template>
  <div class="wrapper">
    <div v-if="isDetails">
      <div class="page_container">
        <span @click="handleClearDetails">
          <BackComponent :title="$t('betDetails')" disable />
        </span>
      </div>
      <MyBetDetails
        :bet="currentDetails"
        @close="handleClearDetails"
        :getStatusNameCasino="getStatusNameCasino"
        :getStatusName="getStatusName"
      />
    </div>
    <div v-else class="page_container">
      <BackComponent :title="$t('myBets')" />
      <section class="bets-menu">
        <div
          class="tablinks"
          :class="isActive('sports')"
          @click="setActive('sports')"
        >
          {{ $t("sports") }}
        </div>
        <div
          class="tablinks"
          :class="isActive('games')"
          @click="setActive('games')"
        >
          {{ $t("games") }}
        </div>
        <div
          class="tablinks"
          :class="isActive('jackpot')"
          @click="setActive('jackpot')"
        >
          {{ $t("jackpot") }}
        </div>
        <div
          class="tablinks"
          :class="isActive('freeBet')"
          @click="setActive('freeBet')"
        >
          {{ $t("freeBet") }}
        </div>
      </section>
      <div class="filter" v-if="isActive('games')">
        <button @click="handleAction('won_status')">
          <span>
            <span>{{ selectedCasinoStatus.name }}</span>
          </span>
          <span><CaretDown /></span>
        </button>
        <button @click="handleAction('bet_type')">
          <span>{{ selectedBetType.name }}</span> <span><CaretDown /></span>
        </button>
      </div>
      <div v-if="isActive('jackpot') || isActive('freeBet')"></div>
      <div class="filter" v-if="isActive('sports')">
        <button @click="handleAction('status')">
          <span>
            <span>{{ selectedStatus.name }}</span>
          </span>
          <span><CaretDown /></span>
        </button>
        <button @click="handleAction('time')">
          <Calendar />
          <span>{{ selectedTime.name }}</span> <span><CaretDown /></span>
        </button>
      </div>
      <div v-if="loadingGames">
        <MyBetsLoader :count="11" />
      </div>
      <MyBetList
        :betsData="betsData"
        :loadingGames="loadingGames"
        :isActive="isActive"
        :getStatusNameCasino="getStatusNameCasino"
        :getStatusName="getStatusName"
        :formatDateTime="formatDateTime"
        :handleDetailsScreen="handleDetailsScreen"
      />
    </div>
    <!-- <MyBetsFilter
      :isOpen="isSlideUpOpen"
      :closeSlideUp="closeSlideUp"
      :casinoStatus="casinoStatus"
      :actionType="actionType"
      :handleSelectedStatus="handleSelectedStatus"
      :selectedCasinoStatus="selectedCasinoStatus"
      :selectedStatus="selectedStatus"
      :timeFilter="timeFilter"
      :statusFilter="statusFilter"
      :selectedTime="selectedTime"
      :handleSelectedDate="handleSelectedDate"
      :casinoBetType="casinoBetType"
      :selectedBetType="selectedBetType"
    /> -->
    <MyBetsFilter
      :isOpen="isSlideUpOpen"
      :closeSlideUp="closeSlideUp"
      :casinoStatus="casinoStatus"
      :actionType="actionType"
      :handleSelectedStatus="handleSelectedStatus"
      :selectedCasinoStatus="selectedCasinoStatus"
      :selectedStatus="selectedStatus"
      :timeFilter="timeFilter"
      :statusFilter="statusFilter"
      :selectedTime="selectedTime"
      :handleSelectedDate="handleSelectedDate"
      :casinoBetType="casinoBetType"
      :selectedBetType="selectedBetType"
      :selectedStartDate="selectedStartDate"
      :selectedEndDate="selectedEndDate"
      :handleDateChange="handleDateChange"
      :handleSubmitDate="handleSubmitDate"
      :timeCustom="timeCustom"
    />

    <div v-if="betsData && betsData.length > 0">
      <scroll-loader
        v-show="betsData.length > 0"
        :loader-method="loadOnScroll"
        :loader-disable="is_busy || page >= last_page"
      >
        <div></div>
      </scroll-loader>
    </div>
  </div>
</template>

<script>
import axios from "@/services/api";
import MyBetDetails from "./components/MyBetDetails.vue";
import BackComponent from "../../components/ui/BackComponent.vue";
import CaretDown from "../../components/icons/CaretDown.vue";
import {
  comaFormated,
  formatBetStatusOrOutcome,
  formatDate,
  formatDateTime,
} from "../../utils/formatter";
import MyBetsLoader from "./components/MyBetsLoader.vue";
import MyBetsFilter from "./components/MyBetsFilter.vue";
import {
  fetchCasinoBets,
  fetchFreeBets,
  fetchJackpotBets,
  fetchMyBets,
} from "../../actions/bet";
import MyBetList from "./components/MyBetList.vue";
import Calendar from "../../components/icons/Calendar.vue";

export default {
  name: "Bets",
  components: {
    BackComponent,
    MyBetDetails,
    MyBetsLoader,
    MyBetList,
    CaretDown,
    MyBetsFilter,
    Calendar,
  },
  data: function () {
    const today = new Date().toISOString().substr(0, 10);
    return {
      lang: this.$i18n.locale,
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      isDetails: false,
      page: 1,
      per_page: 10,
      last_page: 1,
      timeCustom: false,
      selectedStartDate: today,
      selectedEndDate: today,
      start: "",
      end: "",
      isSlideUpOpen: false,
      loading: true,
      loadingGames: true,
      is_busy: false,
      selectedTime: { name: this.$t("timePeriod"), value: "Show All" },
      bets: [],
      jackpotResults: [],
      free_bets: [],
      betsData: [],
      goto: "/games",
      bet_status: -2,
      casino_status: -2,
      bet_type: 1,
      selectedStatus: { name: this.$t("betStatus"), value: "Show All" },
      selectedDate: { name: this.$t("showAll"), value: "Show All" },
      currentDetails: {},
      active_menu: "sports",
      actionType: "status",
      firstTimeLoading: true,
      selectedCasinoStatus: { name: this.$t("betStatus"), value: "Show All" },
      selectedBetType: { name: this.$t("betType"), value: "Normal bet" },
      casinoStatus: [
        {
          id: 1,
          name: this.$t("showAll"),
          value: "Show All",
          casino_status: -2,
        },
        { id: 1, name: this.$t("won"), value: "Won", casino_status: 1 },
        { id: 2, name: this.$t("lost"), value: "Lost", casino_status: -1 },
      ],
      casinoBetType: [
        {
          id: 1,
          name: this.$t("normalBet"),
          value: "Normal bet",
          bet_type: 1,
        },
        { id: 2, name: this.$t("freeSpin"), value: "Free spin", bet_type: 2 },
      ],
      statusFilter: [
        { id: 1, name: this.$t("showAll"), value: "Show All", bet_status: -2 },
        { id: 2, name: this.$t("won"), value: "Won", bet_status: 2 },
        { id: 3, name: this.$t("lost"), value: "Lost", bet_status: 1 },
        { id: 4, name: this.$t("pending"), value: "Pending", bet_status: 0 },
        { id: 6, name: this.$t("voided"), value: "Voided", bet_status: 3 },
        {
          id: 5,
          name: this.$t("cancelled"),
          value: "Cancelled",
          bet_status: -1,
        },
        {
          id: 7,
          name: this.$t("cashoutGame"),
          value: "Cash Out",
          bet_status: 5,
        },
      ],
      timeFilter: [
        { id: 1, name: this.$t("showAll"), value: "Show All", bet_time: 0 },
        { id: 2, name: this.$t("today"), value: "Today", bet_time: 1 },
        {
          id: 3,
          name: this.$t("thisWeek"),
          value: "This Week",
          bet_time: 2,
        },
        {
          id: 4,
          name: this.$t("thisMonth"),
          value: "This Month",
          bet_time: 3,
        },
        { id: 5, name: this.$t("customDate"), value: "Custom Date" },
      ],
    };
  },
  mounted() {
    // this.$store.dispatch("setCurrentPage", "bets");
    // console.log(this.jackpotId);
    // if (params) {
    //   this.setActive("jackpot");
    //   return;
    // }
    const jackpotParams = this.$route.params.id;

    if (jackpotParams) {
      this.myJackpotBets();
      this.setActive("jackpot");
      return;
    }

    this.myBets();
  },
  watch: {
    active_menu(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadingGames = true;
        this.page = 1;
        this.last_page = 1;
        this.betsData = [];
        this.is_busy = false;
        this.firstTimeLoading = true;

        if (this.isDetails) {
          return;
        }
        if (newValue === "sports") {
          this.myBets();
          return;
        }
        if (newValue === "games") {
          this.myCasino();
          return;
        }
        if (newValue === "jackpot") {
          this.myJackpotBets();
          return;
        }
        if (newValue === "freeBet") {
          this.myFreeBets();
          return;
        }
      }
    },
    bet_status() {
      this.loadingGames = true;
      this.page = 1;
      this.fetchBet();
    },
    start() {
      this.loadingGames = true;
      this.page = 1;
      this.fetchBet();
    },
    end() {
      this.loadingGames = true;
      this.page = 1;
      this.fetchBet();
    },
    casino_status() {
      this.loadingGames = true;
      this.page = 1;
      this.fetchBet();
    },
    bet_type() {
      this.loadingGames = true;
      this.page = 1;
      this.fetchBet();
    },
  },
  methods: {
    comaFormated,
    loadOnScroll: function () {
      if (this.betsData) {
        this.fetchBet();
      }
    },
    formatDateTime,
    formatDate,
    formatBetStatusOrOutcome,
    closeSlideUp() {
      this.isSlideUpOpen = false;
      this.timeCustom = false;
    },
    handleDateChange(value, type) {
      if (type === "start") {
        this.selectedStartDate = value;
      } else {
        this.selectedEndDate = value;
      }
    },
    handleSubmitDate() {
      if (this.selectedStartDate && this.selectedEndDate) {
        const nameAndValue = {
          name: this.$t("customDate"),
          value: "Custom Date",
        };
        this.start = this.selectedStartDate;
        this.end = this.selectedEndDate;
        this.closeSlideUp();
        this.selectedTime = nameAndValue;
        this.page = 1;
      }
    },
    handleSelectedStatus(status) {
      const { name, value } = status;
      const nameAndValue = { name, value };

      this.page = 1;
      if (status.casino_status) {
        this.casino_status = status.casino_status;
      }
      if (status.bet_type) {
        this.bet_type = status.bet_type;
      }
      if (status.bet_status || status.bet_status === 0) {
        this.bet_status = status.bet_status;
      }
      if (status.bet_time || status.bet_time === 0) {
        this.bet_time = status.bet_time;
      }

      if (this.actionType === "status") {
        this.selectedStatus = nameAndValue;
      }

      if (this.actionType === "won_status") {
        this.selectedCasinoStatus = nameAndValue;
      }

      if (this.actionType === "status") {
        this.selectedType = nameAndValue;
      }

      if (this.actionType === "bet_type") {
        this.selectedBetType = nameAndValue;
      }
      this.closeSlideUp();
    },
    handleSelectedDate(time) {
      const today = new Date();
      let startDate, endDate;

      if (time.value === "Custom Date") {
        console.log(time, "timetimetiemtiem");
        this.timeCustom = true;
        return;
      }

      if (time.bet_time === 0) {
        this.start = "";
        this.end = "";
      } else if (time.bet_time === 1) {
        startDate = today;
        endDate = today;
      } else if (time.bet_time === 2) {
        const firstDayOfWeek = new Date(
          today.setDate(today.getDate() - today.getDay())
        );
        startDate = new Date(firstDayOfWeek);
        endDate = new Date();
      } else if (time.bet_time === 3) {
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date();
      }
      this.start = startDate ? startDate.toISOString().split("T")[0] : "";
      this.end = endDate ? endDate.toISOString().split("T")[0] : "";
      this.selectedTime = { name: time.name, value: time.value };
      this.closeSlideUp();
    },
    handleAction(type) {
      this.actionType = type;
      this.isSlideUpOpen = true;
    },
    handleDetailsScreen(bet) {
      this.currentDetails = bet;
      this.isDetails = true;
    },
    handleClearDetails() {
      this.isDetails = false;
      this.currentDetails = {};
    },
    getDataTarget: function (id) {
      return "#collapseMybet" + id;
    },
    getArialControl: function (id) {
      return "collapseMybet" + id;
    },
    isActive: function (menu) {
      return this.active_menu === menu ? "active" : "";
    },
    setActive: function (menu) {
      this.active_menu = menu;
      this.start = "";
      this.end = "";
    },
    getStatusString(processing_status) {
      switch (processing_status) {
        case 0:
          return "pending";
        case 1:
          return "lost";
        case 2:
          return "won";
        case 3:
          return "voided";
        case -1:
          return "cancelled";
        case 5:
          return "cashout";
        default:
          return "error";
      }
    },
    getBetFilterStatus: function (bet) {
      switch (parseInt(bet.status)) {
        case 0:
          return "pending";

        case 1:
          return "lost";

        case 2:
          return "won";

        case 3:
          return "voided";

        case 4:
          return "cancelled";

        case 5:
          return "cashout";
      }
    },
    shareBet: function (code, odds) {
      var vm = this;
      var ob = {
        code: code,
        odds: odds,
      };
      vm.EventBus.$emit("share:bet", ob);
    },
    copyShareBetLink: function (code) {
      var link = "https://dev.chopbet.ci/share/" + code;
      this.copyToClipboard(link);
      this.copyText = "Copied";
    },
    getCounterClass: function (status) {
      if (parseInt(status) === parseInt(this.filter_status)) {
        return "bets-counter-selected";
      }

      return "bets-counter-unselected";
    },
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },
    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },
    getMatchClass: function (picked) {
      if (parseInt(picked.status) === 0) {
        return "bd-callout-pending";
      }

      if (parseInt(picked.status) === 1) {
        return "bd-callout-pending";
      }

      if (parseInt(picked.status) === -1) {
        return "bd-callout-cancel";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
        return "bd-callout-won";
      }

      if (parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {
        return "bd-callout-won";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
        return "bd-callout-lost";
      }
    },
    viewAll: function () {
      this.view_more = false;
    },
    setFilter: function (filter_status) {
      this.filter_status = filter_status;
    },
    getMyBetLabelClass: function (bet) {
      switch (parseInt(bet.filter_status)) {
        case 0:
          return "bet-text-pending";

        case 1:
          return "bet-text-lost";

        case 2:
          return "bet-text-won";

        case 3:
          return "bet-text-voided";

        case 4:
          return "bet-text-cancelled";

        case 5:
          return "bet-text-won";
      }
      return "";
    },
    getBetStatusName: function (bet) {
      switch (parseInt(bet.filter_status)) {
        case 0:
          return "pending";

        case 1:
          return "lost";

        case 2:
          return "won";

        case 3:
          return "voided";

        case 4:
          return "cancelled";

        case 5:
          return "cashout";
      }
      return "";
    },
    getBetsBorderClass: function (bet) {
      switch (parseInt(bet.filter_status)) {
        case 0:
          return "bets-item-pending";

        case 1:
          return "bets-item-lost";

        case 2:
          return "bets-item-won";

        case 3:
          return "bets-item-voided";

        case 4:
          return "bets-item-cancelled";

        case 5:
          return "bets-item-won";
      }
      return "";
    },
    toggleCollapse(jackpotId) {
      const elementId = "jackpot_" + jackpotId;
      const element = document.getElementById(elementId);
      if (element.classList.contains("show")) {
        element.classList.remove("show");
      } else {
        element.classList.add("show");
      }
    },
    async myBets() {
      const { is_busy, page, per_page, bet_status, start, end } = this;

      if (is_busy) return;

      if (parseInt(page) > parseInt(this.last_page)) {
        this.is_busy = false;
        return;
      }
      this.is_busy = true;

      try {
        const apiKey = this.getAuth();
        const params = {
          page,
          per_page,
          apiKey,
          status: bet_status,
          start,
          end,
        };
        const bets = await fetchMyBets(params);
        if (!bets || !Array.isArray(bets.data)) {
          this.betsData = [];
          return;
        }

        if (bets.data.length === 0) {
          this.is_busy = false;
          return;
        }

        this.last_page = bets.last_page;
        if (parseInt(page) === 1) {
          this.betsData = bets.data;
        } else {
          this.betsData.push(...bets.data);
        }
        this.page = parseInt(page) + 1;
      } catch (error) {
        this.loadingGames = false;
        if ([401, 400, 428].includes(error?.response?.status)) {
          this.setError(this.$t("sessionExpired"));
          this.logout();
          return;
        }
        this.setError(error?.response?.data?.message || "An error occurred");
      } finally {
        this.is_busy = false;
        this.loadingGames = false;
      }
    },
    async myFreeBets() {
      this.loadingGames = true;
      try {
        const profile = this.getProfile();
        const res = await fetchFreeBets(this.getAuth(), profile);
        this.betsData = res || [];
      } catch (error) {
        this.handleFetchError(error);
      } finally {
        this.is_busy = false;
        this.loadingGames = false;
      }
    },
    async myCasino() {
      const { is_busy, page, per_page, casino_status, bet_type, start, end } =
        this;
      if (is_busy || parseInt(page) > parseInt(this.last_page)) {
        return;
      }
      this.is_busy = true;
      try {
        const apiKey = this.getAuth();
        const params = {
          page,
          per_page,
          apiKey,
          won: casino_status,
          bet_type: bet_type,
          start,
          end,
        };
        const bets = await fetchCasinoBets(params);

        if (!bets || !Array.isArray(bets.data)) {
          this.betsData = [];
          return;
        }
        if (bets.data.length === 0) {
          this.is_busy = false;
          return;
        }
        this.last_page = bets.last_page;

        if (parseInt(page) === 1) {
          this.betsData = bets.data;
        } else {
          this.betsData.push(...bets.data);
        }
        this.page = parseInt(page) + 1;
        console.log(this.page);
      } catch (error) {
        this.handleFetchError(error);
      } finally {
        this.is_busy = false;
        this.loadingGames = false;
      }
    },
    async myJackpotBets() {
      const { is_busy, page, per_page, jackpotId, bet_status } = this;

      if (is_busy || parseInt(page) > parseInt(this.last_page)) {
        return;
      }
      this.is_busy = true;

      this.loadingGames = true;
      try {
        const apiKey = this.getAuth();
        var params = { page, per_page, apiKey, jackpotId, status: bet_status };
        const res = await fetchJackpotBets(params);
        this.betsData = res || [];
      } catch (error) {
        this.handleFetchError(error);
      } finally {
        this.loadingGames = false;
      }
    },
    handleFetchError(error) {
      this.loadingGames = false;
      if (error.response) {
        if (parseInt(error.response.data.status) === 401) {
          this.setError(`${this.$t("sessionExpired")}`);
          this.logout();
          return;
        }
        this.setError(error.response.data.error_message);
      }
    },
    fetchBet() {
      const activeMenu = this.active_menu;
      this.is_busy = false;
      if (activeMenu === "games") {
        this.myCasino();
        return;
      }
      if (activeMenu === "jackpot") {
        this.myJackpotBets();
        return;
      }
      if (activeMenu === "freeBet") {
        this.myFreeBets();
        return;
      }
      if (activeMenu === "sports") {
        this.myBets();
        return;
      }
    },
    requestCashout: function (bet_id) {
      var p = this.getProfile();
      if (!p) {
        this.setError(this.$t("pleaseLoginProceed"));

        this.$router.push({ name: "login", params: {} });
        return;
      }
      var vm = this;
      var path = process.env.VUE_APP_URL_REQUEST_CASHOUT.replace(
        "{profile_id}",
        p.d
      );
      var data = {
        bet_id: bet_id,
      };
      vm.cashoutLoading = "loading";
      axios
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          //var msg = res;
          var amount = res.data.message.amount;
          //var description = res.data.message.description;
          // console.log("GOT cashout amount here " + JSON.stringify(amount));

          //text: "Cashout this bet at {{ fiatCurrency}}. "+vm.formatCurrency(amount),

          vm.$swal
            .fire({
              title:
                `Cashout this bet at ${this.fiatCurrency}.` +
                vm.formatCurrency(amount),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Cashout",
            })
            .then((result) => {
              if (result.isConfirmed) {
                var path = process.env.VUE_APP_URL_ACCEPT_CASHOUT.replace(
                  "{profile_id}",
                  p.d
                );

                var data = {
                  bet_id: bet_id,
                };

                axios
                  .post(path, JSON.stringify(data), {
                    headers: {
                      "api-key": vm.getAuth(),
                    },
                  })
                  .then((res) => {
                    vm.cashoutLoading = "";
                    console.log("RESPONSE ==> " + JSON.stringify(res));
                    vm.$swal.fire(
                      "Submitted!",
                      "Your cashout is being processed, you will be notified shortly",
                      "success"
                    );
                  })
                  .catch((err) => {
                    vm.cashoutLoading = "";

                    vm.$swal.fire(
                      "Failed!",
                      "Error occurred processing your cashout request",
                      "error"
                    );

                    if (err.response) {
                      var message = "";

                      if (parseInt(err.response.status) === 428) {
                        message = err.response.data.message;

                        this.setError(message);

                        return;
                      }

                      if (parseInt(err.response.status) === 401) {
                        this.setError(`${this.$t("sessionExpired")}`);

                        // vm.logout();
                        return;
                      } else {
                        message = err.response.data.message;
                        this.setError(message);
                      }
                    } else if (err.request) {
                      this.setError("Please check your network");
                    } else {
                      //vm.setError("Failed", "Please check your network")
                      // console.log(JSON.stringify(err));
                    }
                  });
              } else {
                vm.cashoutLoading = "";
              }
            });
        })
        .catch((err) => {
          vm.cashoutLoading = "";

          console.log("RESPONSE HERE " + JSON.stringify(err));
          vm.$swal.fire(
            "Failed!",
            "Cashout for your bet is not available at the moment",
            "error"
          );
        });
    },
    selectedClass: function (filter_status) {
      return this.filter_status === filter_status
        ? "bets-tab-text btn-selected"
        : "bets-tab-text btn-normal";
    },

    getStatusName: function (picked) {
      if (picked.status === -1) {
        return `${this.$t("cancelled")}`;
      }
      if (picked.status === 0) {
        return `${this.$t("pending")}`;
      }
      if (picked.status === 1) {
        return `${this.$t("lost")}`;
      }
      if (picked.status === 2) {
        return `${this.$t("won")}`;
      }
      if (picked.status === 3) {
        return `${this.$t("voided")}`;
      }
      if (picked.status === 5) {
        return `${this.$t("cashout")}`;
      }
      if (picked.won === 1) {
        return `${this.$t("won")}`;
      }
      if (picked.won === 0) {
        return `${this.$t("lost")}`;
      }
      return "";
    },
    getStatusNameCasino: function (picked) {
      if (picked.status === 2) {
        return "won";
      }
      if (picked.status === -1) {
        return `${this.$t("cancelled")}`;
      }
      if (picked.status === 0) {
        return `${this.$t("pending")}`;
      }
      if (picked.status === 1) {
        return `${this.$t("lost")}`;
      }
      if (picked.status === 2) {
        return `${this.$t("won")}`;
      }
      if (picked.status === 3) {
        return `${this.$t("voided")}`;
      }
      if (picked.status === 5) {
        return `${this.$t("cashout")}`;
      }
      if (picked.won === 1) {
        return `${this.$t("won")}`;
      }
      if (picked.won === 0) {
        return `${this.$t("pending")}`;
      }
      if (picked.won === -1) {
        return `${this.$t("lost")}`;
      }
      return "";
    },
    getBetLabelClass: function (picked) {
      if (parseInt(picked.status) === 0) {
        return "bet-text-pending";
      }

      if (parseInt(picked.status) === 1) {
        return "bet-text-pending";
      }

      if (parseInt(picked.status) === -1) {
        return "bet-text-cancel";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
        return "bet-text-won";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
        return "bet-text-lost";
      }

      if (parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {
        return "bet-text-won";
      }

      return "";
    },
    openCity: function (cityName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");

      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      tablinks = document.getElementsByClassName("tablinks");

      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      if (document.getElementById(cityName)) {
        document.getElementById(cityName).style.display = "block";
      }

      this.className += " active";
    },
    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "unique-id-" + index + "-");
    },
  },
  computed: {
    jackpotId() {
      return this.$route.params.id ?? 2;
    },
    profile: function () {
      return this.getProfile();
    },
    filteredBets: function () {
      var vm = this;

      if (vm.filter_status === -10) {
        return this.bets;
      }

      var bets = [];
      this.jQuery.each(this.bets, function (k, v) {
        if (parseInt(v.processing_status) === parseInt(vm.filter_status)) {
          bets.push(v);
        }
      });

      return bets;
    },
    borderClass: function () {
      switch (parseInt(this.filter_status)) {
        case 0:
          return "bets-item-pending";

        case 1:
          return "bets-item-lost";

        case 2:
          return "bets-item-won";

        case 3:
          return "bets-item-voided";

        case 4:
          return "bets-item-cancelled";
      }

      return "";
    },
    statusName: function () {
      switch (parseInt(this.filter_status)) {
        case 0:
          return "Pending";

        case 1:
          return "Lost";

        case 2:
          return "Won";

        case 3:
          return "Voided";

        case 4:
          return "Cancelled";
      }
      return "";
    },
    betLabelClass: function () {
      switch (parseInt(this.filter_status)) {
        case 0:
          return "bet-text-pending";

        case 1:
          return "bet-text-lost";

        case 2:
          return "bet-text-won";

        case 3:
          return "bet-text-voided";

        case 4:
          return "bet-text-cancelled";
      }

      return "";
    },
  },
};
</script>

<style scoped src="./components/index.css"></style>
